import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';

export type AboutYourPolicyQuestions = {
  coverStartDate: Question & { youngPetExplanatoryText: string };
  marketingConsent: Question & {
    yesLabel: string;
    noLabel: string;
  };
  jlfsConsent: Question & {
    yesLabel: string;
    noLabel: string;
  };
  marketingPartners: Question;
};

type csPetAboutYourPolicyQuestions = {
  csPetAboutYourPolicyQuestionsV2: {
    cover_start_date: CsQuestion & {
      main_details: { young_pet_explanatory_text: string };
    };
    marketing_consent: CsQuestion & {
      yes_button_text: string;
      no_button_text: string;
    };
    jlfs_consent: CsQuestion & {
      yes_button_text: string;
      no_button_text: string;
    };
    marketing_partners: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestionsV2 {
      cover_start_date {
        main_details {
          question_text
          explanatory_text
          young_pet_explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      marketing_consent {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        yes_button_text
        no_button_text
      }
      jlfs_consent {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        yes_button_text
        no_button_text
      }
      marketing_partners {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useAboutYourPolicyQuestions = (): AboutYourPolicyQuestions => {
  const csQuestions = useStaticQuery<csPetAboutYourPolicyQuestions>(query);
  const { processQuestion } = useQuestionProcessor(PageTitle.AboutYourPolicy);

  return {
    coverStartDate: {
      ...processQuestion(csQuestions.csPetAboutYourPolicyQuestionsV2.cover_start_date),
      youngPetExplanatoryText:
        csQuestions.csPetAboutYourPolicyQuestionsV2.cover_start_date.main_details
          .young_pet_explanatory_text,
    },
    marketingConsent: {
      ...processQuestion(csQuestions.csPetAboutYourPolicyQuestionsV2.marketing_consent),
      yesLabel:
        csQuestions.csPetAboutYourPolicyQuestionsV2.marketing_consent.yes_button_text,
      noLabel:
        csQuestions.csPetAboutYourPolicyQuestionsV2.marketing_consent.no_button_text,
    },
    jlfsConsent: {
      ...processQuestion(csQuestions.csPetAboutYourPolicyQuestionsV2.jlfs_consent),
      yesLabel: csQuestions.csPetAboutYourPolicyQuestionsV2.jlfs_consent.yes_button_text,
      noLabel: csQuestions.csPetAboutYourPolicyQuestionsV2.jlfs_consent.no_button_text,
    },
    marketingPartners: {
      ...processQuestion(csQuestions.csPetAboutYourPolicyQuestionsV2.marketing_partners),
    },
  };
};

export default useAboutYourPolicyQuestions;
