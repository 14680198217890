import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import FormFooter from 'components/FormFooter';

export const FormFooterWithMargin = styled(FormFooter)`
  && {
    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(11)};
    `}
  }
`;
